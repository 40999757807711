body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
.App {
  display: flex;
}

.sidebar {
  width: 200px; 
}

.content {
  flex: 1;
  margin-left: 15px;
}


.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 10px;
}

.sidebar li a {
  color: #fff;
  text-decoration: none;
}

.sidebar li a:hover {
  background-color: #555;
}

.home {
  text-align: center;
}

.cutting-page {
}